.ctn_border {
    background: linear-gradient(-70deg, rgb(133, 176, 95), rgb(89, 100, 150), rgb(85, 77, 141));
    max-width: 1140px;
    padding: 0.2rem;
}

.ctn_bg {
    background-color: #1d1d20;
    padding: 5rem;
}

.contact a,
.contact p {
    text-decoration: none;
    color: #ecf0f1;
}

.contact article {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 30rem;
    margin: 1rem auto;
    padding: 1rem;
}

.contact article * {
    padding: 1rem;
}

.icon {
    color: rgb(133, 176, 95);
    font-size: 2.5rem;
}

.contact a:hover,
.contact p:hover {
    color: rgb(133, 176, 95);
}

/* *****************************************************************
VERSION TABLETTE
***************************************************************** */

@media screen and (min-width: 760px) {
    .ctn_bg {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .ctn_bg div {
        display: block;
        min-width: 50%;
    }

    .ctn_bg h2 {
        min-width: 100%;
        margin-bottom: 2rem;
    }
}

/* *****************************************************************
VERSION DESKTOP
***************************************************************** */

@media screen and (min-width: 1100px) {}
.footer {
    text-align: center;
    font-family: arvo;
    font-size: 1.6rem;
    padding: 2rem;
}

.footer nav {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
}

.footer nav :hover {
    background-color: #24272b;
    border-radius: 0.5rem;
}

.footer nav :active {
    background-color: #1f2d3b;
    border-radius: 0.5rem;
}

.footer a {
    text-decoration: none;
    color: #ecf0f1;
    padding: 1rem;
}


/* *****************************************************************
VERSION TABLETTE
***************************************************************** */

@media screen and (min-width: 760px) {
    .footer nav {
        flex-direction: row;
        justify-content: space-around;
    }
    .footer a {
        padding: 2rem;
        margin: 2rem;
    }
}


/* *****************************************************************
VERSION DESKTOP
***************************************************************** */

@media screen and (min-width: 1100px) {
    .footer {
        max-width: 1140px;
        margin: auto;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.8rem;
    font-family: poppins;
    background-color: #1d1d20;
    color: #ecf0f1;
}

main {
    font-size: 1.6rem;
}

/* *****************************************************************
VERSION TABLETTE
***************************************************************** */

@media screen and (min-width: 760px) {
    main {
        margin: auto;
    }
}


/* *****************************************************************
VERSION DESKTOP
***************************************************************** */

@media screen and (min-width: 1100px) {
    main {
            max-width: 1140px;
            margin: auto;
        }
}
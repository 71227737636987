.header {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem 0.5rem;
}

.header nav {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.header nav a {
    text-decoration: none;
    font-family: arvo;

    color: #ecf0f1;
    background-color: #1d1d20;

    margin: 0.5rem 1.5rem;
    padding: 1.5rem;
}

.header nav :hover {
    background-color: #24272b;
    border-radius: 0.5rem;
}

.header nav :active {
    background-color: #1f2d3b;
    border-radius: 0.5rem;
}

.header img {
    width: 12rem;
    margin: auto;
}

hr {
    width: 5rem;
    margin: auto;
    background: linear-gradient(-70deg, rgb(133, 176, 95), rgb(89, 100, 150), rgb(85, 77, 141));
    height: 0.2rem;
    border: 0;
}

/* *****************************************************************
VERSION TABLETTE
***************************************************************** */

@media screen and (min-width: 760px) {
    .header nav {
        flex-direction: row;
        justify-content: space-around;
    }

    .header nav a {
        width: 25%;
    }

    hr {
        width: 0;
        height: 0;
    }

    .header {
        padding: 2rem;
    }
}


/* *****************************************************************
VERSION DESKTOP
***************************************************************** */

@media screen and (min-width: 1100px) {
    .header {
        flex-direction: row;
        justify-content: space-between;
        max-width: 1140px;
        margin: auto;
        padding: 4rem;
    }

    .header img {
        margin: 0 5rem;
    }

    .header nav {
        width: 50%;
        padding-top: 2rem;
    }

    .header nav a {
        margin: 0;
        padding: 0;
        height: 3rem;
    }
}
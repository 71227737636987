.ctn_border {
    background: linear-gradient(-70deg, rgb(133, 176, 95), rgb(89, 100, 150), rgb(85, 77, 141));
    max-width: 1140px;
    padding: 0.2rem;
}

.ctn_bg {
    background-color: #1d1d20;
    padding: 5rem;
}

.intro {
    padding-left: 2rem;
    border-left: 0.1rem solid rgb(133, 176, 95);
    margin-top: 3rem;
}

.project img {
    max-width: 100%;
    max-height: 40rem;
    display: block;
    margin: 1rem auto;
    padding: 0.2rem;
}

.project a {
    text-decoration: none;
    color: #ecf0f1;
    padding: 0.5rem;
    display: block;
    text-align: center;
}

.project a:hover {
    background-color: #292c30;
    border-radius: 0.5rem;
}

.project a:active {
    background-color: #1f2d3b;
    border-radius: 0.5rem;
}

.project article {
    background-color: #24272b;
    margin: 3rem auto 0;
    max-width: 42rem;
    border-radius: 0.5rem;
}

.project p {
    padding: 1rem;
}

.project h3 {
    padding: 1rem 0 0 1rem;
}

/* *****************************************************************
VERSION TABLETTE
***************************************************************** */

@media screen and (min-width: 760px) {}


/* *****************************************************************
VERSION DESKTOP
***************************************************************** */

@media screen and (min-width: 1100px) {}